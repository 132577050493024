import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/awards/standard-graphics"

    return (
        <Layout pageTitle="Awards - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/awards/sme-awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <p className="para">
                        Get even more eyes on your business by sharing and
                        encouraging your network to get involved in this year's
                        awards ceremony!
                    </p>
                    <p className="para">
                        Elevate your status as an award nominee and maybe even an AWARD WINNER!
                    </p>
                    <p className="para">
                        To make things easier, we've created some images for you
                        to share, and you can use our marketing copy below.
                    </p>
                </Col>
            </Row>
            <ImageDownload
                root={root}
                name="Awards Promo"
                filename="sme-awards-promo"
                text="Use this template and ready-to-go copy to share the Women's Business Awards with our audience."
                squareHref="https://www.canva.com/design/DAFeHo0lacA/OqK5bDNEA_r8dGd2-GqwOQ/view?utm_content=DAFeHo0lacA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFeHf6QP6k/GhyAlVWDZbnTHrSdskaa9w/view?utm_content=DAFeHf6QP6k&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/10puqRIqzuL1TfqG1yAitHJGZhCpBrmJrJVIRgRuz6AQ/edit?usp=sharing"
            />
        </Layout>
    )
}

export default IndexPage
